import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// components
import { BaseButton, BaseSpacer } from '@/components/partials';
import { ReturnTemplate } from '@/components/features';

// types
import type { Component, ComputedRef } from 'vue';
import type { WopsUserInterface } from '@/classes/user';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
const router = useRouter();

const canSubmitReturn: ComputedRef<boolean> = computed(() => store.getters['returnOrder/canSubmitReturn']);

const goToConfirmReturn = (user: WopsUserInterface): void => {
  router.push({ name: 'Confirm Return', params: { user_id: user.id as string } });
};

const processedReturnCount: ComputedRef<number> = computed(
  () => store.getters['returnOrder/getProcessedReturnCount']
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ReturnTemplate), { editable: true }, {
    default: _withCtx((slotProps) => [
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseButton), {
        color: "success",
        type: "submit",
        ref: "confirm",
        onClick: ($event: any) => (goToConfirmReturn(slotProps.user)),
        "button-disabled": !canSubmitReturn.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(`${processedReturnCount.value}/${slotProps.returnOrderItemsLength} ${
            canSubmitReturn.value ? 'processed: Submit return' : 'processed'
          }`), 1)
        ]),
        _: 2
      }, 1032, ["onClick", "button-disabled"])
    ]),
    _: 1
  }))
}
}

})